import request from '@/utils/request'
// 添加菜单
export function menuAdd (params) {
  return request({
    url: '/menu/add',
    method: 'post',
    data: params
  })
}

// 获取列表
export function menuList (params) {
  return request({
    url: '/menu/listAll',
    method: 'get',
    params: params
  })
}

// 获取详细信息
export function menuInfo (params) {
  return request({
    url: '/menu/getOne',
    method: 'get',
    params: params
  })
}

// 编辑菜单
export function menuEdit (params) {
  return request({
    url: '/menu/edit',
    method: 'post',
    data: params
  })
}

// 删除菜单
export function menuDel (params) {
  return request({
    url: '/menu/del',
    method: 'post',
    data: params
  })
}

// 菜单图标
export function menuIcon(params) {
  return request({
    url: '/menu/list_wa_menu_icon',
    method: 'get',
    data: params
  })
}

// 获取菜单接口权限配置
export function getMenuInterfaceConfig(menuId) {
  return request({
    url: '/menu/get_menu_interface_config',
    method: 'get',
    params: {menuId}
  })
}

// 新增菜单接口权限配置
export function addMenuInterfaceConfig(data) {
  return request({
    url: '/menu/add_menu_interface_config',
    method: 'post',
    data: data
  })
}

// 删除菜单接口权限配置
export function delMenuInterfaceConfig(data) {
  return request({
    url: '/menu/del_menu_interface_config',
    method: 'post',
    data: data
  })
}